<template>
  <v-app dark :class="{ blur: $route.meta.dialogCloseRegistration}">
    <v-app-bar app height="100px" color="black">
      <v-container  style="max-width: 1440px;">
        <v-row>
          <v-col class="mt-0">
            <v-img
              class="shrink"
              contain
              src="https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok_biz_homepage/dist/client/089cb4492ca193efad0f2b974d5b336d.svg"
              transition="scale-transition"
              width="224"
            />
          </v-col>

          <v-spacer></v-spacer>
          <!--
          <v-col class="mt-4 d-flex align-end flex-column">
            <v-select
              v-model="lang"
              :items="langs"
              item-text="title"
              item-value="ln"
              label="Select"
              persistent-hint
              single-line
              style="width: 100px; border-bottom: 0px;"
              @input="changelang"
            ></v-select>
          </v-col>
          -->
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main >
      <router-view style="max-width: 1440px;" />
      <v-dialog
        v-model="$route.meta.dialogCloseRegistration"
        persistent
        max-width="600"
      >
        <v-card style="text-align: center">
          <v-card-title class="text-h5" >
            Регистрация на мероприятие завершена
          </v-card-title>
          <v-card-text>
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-footer 
      app 
      absolute
      color="black">
      <v-container style="max-width: 1440px;">
        <v-row  class="d-xs-flex d-sm-none">
          <v-col 
            cols="12"
            class="d-flex align-center flex-column pt-5" 
            style="text-align: right">
            <span class="tt-foter">©2022 TikTok For Business</span>
            <span class="tt-foter ml-3">
              <a href="https://ads.tiktok.com/i18n/official/policy/terms" target="_blank" class="">{{ $t('Terms & policies') }}</a>
            </span>
            <span class="tt-foter ml-3">
              <a href="https://ads.tiktok.com/i18n/official/policy/privacy" target="_blank" class="">{{ $t('Privacy') }}</a>
            </span>
          </v-col>
        </v-row> 

        <v-row class="d-none d-sm-flex" style="padding-left: 80px; padding-right: 80px;">
          <v-col 
            cols="12">
            <span class="tt-foter">©2022 TikTok For Business</span>
            <span class="tt-foter ml-3">
              <a href="https://ads.tiktok.com/i18n/official/policy/terms" target="_blank" class="">{{ $t('Terms & policies') }}</a>
            </span>
            <span class="tt-foter ml-3">
              <a href="https://ads.tiktok.com/i18n/official/policy/privacy" target="_blank" class="">{{ $t('Privacy') }}</a>
            </span>
          </v-col>
        </v-row> 
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    lang: "en",
    langs: [
      { title: "English", ln: "en" },
    ],
  }),

  mounted: function () {
    this.$vuetify.theme.dark = true
    const temp = ['en']
    let lang = localStorage.getItem('lang');
    if(lang){ 
      this.lang = localStorage.getItem('lang');
      this.$i18n.locale = localStorage.getItem('lang');
    } else { 
      lang = this.$route.params.lang;
      if (lang && temp.filter(ii=>ii == lang).length === 1) {
        this.lang = this.$route.params.lang;
        this.$i18n.locale = this.$route.params.lang;
      } else {
      this.lang = this.$i18n.locale;
      localStorage.setItem('lang', this.lang)
      }
    }
  },

  methods: {
    changelang: function(v) {
      this.$i18n.locale = v;
      localStorage.setItem('lang', this.$i18n.locale);
      location.reload()
    } 
  },
};
</script>
<style>
body, html, .v-application {
    font-family: "SofiaPro","PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",HelveticaNeue,Helvetica,Arial,sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    color: #333;
}
.theme--dark.v-application {
    background: #000 !important;
}

p{
  letter-spacing: 1px;
}
.tt-foter {
  text-decoration: none;
  font-size: 16px;
}
.tt-foter a {
  text-decoration: none;
  color: white !important;
  line-height: 140%;
  font-size: 16px;
}
</style>