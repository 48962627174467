import axios from "axios";

export default {
    actions: {
        CustomerDocumentList({ getters }, { values, vm }) {
            return new Promise((resolve, reject) => {
              try {
                  if(!getters) { console.log("error"); }
                values = { ...values };
                axios({
                  url: `${vm.$domain}/api/CustomerDocumentList`,
                  data: values,
                  method: "POST",
                })
                  .then((responce) => {
                    if (
                      responce &&
                      responce.data &&
                      responce.data.status &&
                      responce.data.status.STAT == "ALSOK"
                    ) {
                      resolve(responce.data);
                    } else {
                      //if (!vm.$IsLogout(responce.data.status, vm)) {
                        reject(responce.data.status);
                      //} else {
                      //  reject();
                      //}
                    }
                  })
                  .catch((err) => {
                    //commit("error", err);
                    reject(err);
                  });
              } catch (ex) {
                reject(ex);
              }
            });
        },
        CustomerDocumentAdd({ getters }, { values, vm }) {
          return new Promise((resolve, reject) => {
            try {
                if(!getters) { console.log("error"); }
              values = { ...values };
              axios({
                url: `${vm.$domain}/api/CustomerDocumentAdd`,
                data: values,
                method: "POST",
              })
                .then((responce) => {
                  if (
                    responce &&
                    responce.data &&
                    responce.data.status &&
                    responce.data.status.STAT == "ALSOK"
                  ) {
                    resolve(responce.data);
                  } else {
                    //if (!vm.$IsLogout(responce.data.status, vm)) {
                      reject(responce.data.status);
                    //} else {
                    //  reject();
                    //}
                  }
                })
                .catch((err) => {
                  //commit("error", err);
                  reject(err);
                });
            } catch (ex) {
              reject(ex);
            }
          });
      },
      SendRegistrationForm1({ getters }, { values, vm }) {
        return new Promise((resolve, reject) => {
          try {
              if(!getters) { console.log("error"); }
            values = { ...values };
            axios({
              url: `${vm.$domain}/api/SendRegistrationForm1`,
              data: values,
              method: "POST",
            })
              .then((responce) => {
                if (
                  responce &&
                  responce.data &&
                  responce.data.status &&
                  responce.data.status.STAT == "ALSOK"
                ) {
                  resolve(responce.data);
                } else {
                  //if (!vm.$IsLogout(responce.data.status, vm)) {
                    reject(responce.data.status);
                  //} else {
                  //  reject();
                  //}
                }
              })
              .catch((err) => {
                //commit("error", err);
                reject(err);
              });
          } catch (ex) {
            reject(ex);
          }
        });
    },   
    RegistrationBusinessForm1({ getters }, { values, vm }) {
        return new Promise((resolve, reject) => {
          try {
              if(!getters) { console.log("error"); }
            values = { ...values };
            axios({
              url: `${vm.$domain}/api/RegistrationBusinessForm1`,
              data: values,
              method: "POST",
            })
              .then((responce) => {
                if (
                  responce &&
                  responce.data &&
                  responce.data.status &&
                  responce.data.status.STAT == "ALSOK"
                ) {
                  resolve(responce.data);
                } else {
                  //if (!vm.$IsLogout(responce.data.status, vm)) {
                    reject(responce.data.status);
                  //} else {
                  //  reject();
                  //}
                }
              })
              .catch((err) => {
                //commit("error", err);
                reject(err);
              });
          } catch (ex) {
            reject(ex);
          }
        });
    },      
        CustomerPhoneCheck({ getters }, { values, vm }) {
            return new Promise((resolve, reject) => {
              try {
                  if(!getters) { console.log("error"); }
                values = { ...values };
                axios({
                  url: `${vm.$domain}/api/CustomerPhoneCheck`,
                  data: values,
                  method: "POST",
                })
                  .then((responce) => {
                    if (
                      responce &&
                      responce.data &&
                      responce.data.status &&
                      responce.data.status.STAT == "ALSOK"
                    ) {
                      resolve(responce.data);
                    } else {
                      //if (!vm.$IsLogout(responce.data.status, vm)) {
                        reject(responce.data.status);
                      //} else {
                      //  reject();
                      //}
                    }
                  })
                  .catch((err) => {
                    //commit("error", err);
                    reject(err);
                  });
              } catch (ex) {
                reject(ex);
              }
            });
        },
    }
}