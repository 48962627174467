import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const messages = {
  uz: {
    'Партнер': 'Hamkori',
    'Заказчик': 'Mijoz',
    'firstname': "Ismi",
    'lastname': "Familiya",
    'secondname': 'Otasini ismi',
    'phone': 'Mobil telefon',
    'company': 'Kompaniya',
    'fieldofactivity': "Kompaniyaning faoliyat doirasi",
    'post': 'Lavozim',
    'country': 'Mamlakat',
    'typeofcooperation': 'Hamkorlik turi',
    'cblabel1': 'Shaxsiy ma\'lumotlarni qayta ishlash va pochta jo\'natmalarini qabul qilishga rozilik',
    'cblabel2': '"Taklif shartnomasi" va "Shaxsiy ma\'lumotlarni qayta ishlash to\'g\'risida" ga rozilik',
    'submit': 'Yuborish',
    'Bad email': "Yaroqsiz elektron pochta",
    'Privacy Policy': 'Maxfiylik siyosati',
    'Terms of Use': 'Foydalanish shartlari',
    "required": 'Ma\'lumotlarni kiriting',
    'Successful registration for the event': 'Tadbir uchun muvaffaqiyatli ro\'yxatdan o\'tish',
    "Event Registration error": 'Tadbir uchun ro‘yxatdan o‘tishda xatolik yuz berdi',
    'details': 'Tadbir haqida batafsil',    
    'adress': 'Hyatt Regency Tashkent, st. Navoiy, 1 A, Toshkent, O‘zbekiston',
  },
  en: {
    'Партнер': 'Partner',
    'Заказчик': 'Customer',
    'firstname': "Firstname",
    'lastname': "Lastname",
    'secondname': 'Secondname',
    'phone': 'Mobile phone',
    'company': 'Company',
    'fieldofactivity': "The company's field of activity",
    'post': 'Post ',
    'country': 'A country',
    'typeofcooperation': 'Type of cooperation',
    'cblabel1': 'Consent to the processing of personal data and receiving mailings',
    'cblabel2': 'Consent to the "Offer Agreement" and "For the processing of personal data"',
    'submit': 'Submit',
    'Bad email': "Bad email format",
    'Privacy Policy': 'Privacy Policy',
    'Terms of Use': 'Terms of Use',
    "required": 'Please complete this required field.',
    'Successful registration for the event': 'Successful registration for the event',
    "Event Registration error": 'Ошибка при регистрации на мероприятие',
    'details': 'More about the event',
    'adress': 'Hyatt Regency Tashkent, st. Navoi, 1 A, Tashkent, Uzbekistan',
  },
  ru: {
    'firstname': "Имя",
    'lastname': "Фамилия",
    'secondname': 'Отчество',
    'phone': 'Мобильный телефон',
    'company': 'Компания',
    'fieldofactivity': "Сфера деятельности компании",
    'post': 'Должность ',
    'country': 'Страна',
    'typeofcooperation': 'Тип сотрудничества',
    'cblabel1': 'Согласие на обработку персональных данных и получение рассылок',
    'cblabel2': 'Согласие с "Договором оферты" и "На обработку персональных данных"',
    'submit': 'Отправить',
    'Bad email': "Неверный E-mail",
    'Privacy Policy': 'Политика конфиденциальности',
    'Terms of Use': 'Условия использования',
    "required": 'Заполните поле',
    'Successful registration for the event': 'Успешная регистрация на мероприятие',
    "Event Registration error": 'Ошибка при регистрации на мероприятие',
    'details': 'Подробнее о мероприятии',    
    'adress': 'Хаятт Ридженси Ташкент, ул. Навои, 1 А, Ташкент, Узбекистан',
  },
};
Vue.config.productionTip = false

//Vue.prototype.$domain = "https://rest.efficrm2.ru"; // prod "http://localhost:64899"; // 
Vue.prototype.$domain = "https://tiktok-api.efficrm2.ru"; // prod "http://localhost:64899"; // 
//Vue.prototype.$domain = "https://file-signature-api.efficrm2.ru"; //prod "http://localhost:65010"; // 

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
  fallbackLocale: localStorage.getItem('lang') ? localStorage.getItem('lang') : "en",
  messages,
});


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
