<template>
  <v-container style="padding: 0px 0px 0px 0px">
    <v-row>
      <v-col>
        <v-card
          class="mb-12 mt-4"
          style="background-color: black"
          elevation="0"
        >
          <v-card-body>
            <v-row>              
              <v-col  class="col-12 col-sm-7">
                <v-row>
                  <v-col style="padding: 96px 30px 46px 64px">
                    <h2 class="h2-title">
                      Sign up to the TikTok for Business newsletter
                    </h2>
                    <p class="mt-4">We’ll be in touch with the latest trends, platform news, product updates and more.</p>
                    <v-form
                      ref="form"
                      lazy-validation
                      v-model="valid"
                    >
                      <v-row class="mt-12">
                        <v-col class="col-12">
                          <p class="tb-title">{{$t('firstname') + ' *'}}</p>
                          <v-text-field
                            class="my-1"
                            outlined
                            color="#c7000b"
                            :rounded = "false"
                            hide-details="auto"
                            :rules="reqRules"
                            v-model="fname"
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-12">
                          <p class="tb-title">{{$t('lastname') + ' *'}}</p>
                          <v-text-field
                            class="my-1"
                            outlined
                            color="#c7000b"
                            :rounded = "false"
                            hide-details="auto"
                            :rules="reqRules"
                            v-model="lname"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col class="col-12">
                          <p class="tb-title">{{'Company email *'}}</p>
                          <v-text-field
                            class="my-1"
                            outlined
                            color="#c7000b"
                            :rounded = "false"
                            hide-details="auto"
                            :rules="emailRules"
                            v-model="email"
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-12">
                          <p class="tb-title">Company site / app *</p>
                          <v-text-field
                            class="my-1"
                            outlined
                            color="#c7000b"
                            :rounded = "false"
                            hide-details="auto"
                            :rules="reqRules"
                            v-model="CompanySite"
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-12">
                          <p class="tb-title">{{$t('Industry') + ' *'}}</p>
                          <v-autocomplete
                            v-model="industry"
                            :items="Industrys"
                            item-text="title"
                            item-value="id"
                            :rules="reqRules"
                            single-line
                            hide-details="auto"
                            color="#c7000b"
                            :rounded = "false"
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                          <p class="tb-title">{{$t('country') + ' *'}}</p>
                          <v-autocomplete
                            v-model="Country"
                            :items="Countrys"
                            item-text="title"
                            item-value="id"
                            :rules="reqRules"
                            single-line
                            hide-details="auto"
                            color="#c7000b"
                            :rounded = "false"
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12">
                          <p>By clicking the ‘Sign Up’ button, you agree to TikTok for Business’ <a class="tt-link" href="https://ads.tiktok.com/i18n/official/policy/privacy" rel="noopener" target="_blank">Privacy Policy</a> and that you’re happy to receive our updates. </p>
                        </v-col>
                        <v-col class="col-12">
                          <v-btn
                          x-large
                            elevation="0"
                            color="#fe2c55"
                            justify="end"
                            :loading="isLoadingBtn"
                            :disabled="!valid"
                            @click.prevent="Submit"
                            class="pt-5 pb-6 btn-submit"
                            >{{$t('Sign Up')}}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col-12 col-sm-5 bgimg" style="min-height: 400px">
              </v-col>

            </v-row>
          </v-card-body>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <h2 class="h2-title">
            {{dialogTitle}}
          </h2>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: "Form1",
  data: () => ({
    valid: true,
    isLoading: true,
    isLoadingBtn: false,
    dialog: false,
    dialogText: "",
    dialogTitle: "",
    email: "",
    fname: "",
    sname: "",
    lname: "",
    phone: "",
    pd: "",
    oferta: "",
    TypeCooperation: "",
    Country: "",
    industry: "",
    CompanySite: "",
    position: "",
    fActivity: "",
    company: "",
    Industrys: [
      {id: 'Startup', title: 'Startup'},
      {id: 'Medium and small business', title: 'Medium and small business'},
      {id: 'Marketing / advertising / media agency', title: 'Marketing / advertising / media agency'},
      {id: 'Marketing', title: 'Marketing'},
      {id: 'Entertainment', title: 'Entertainment'},
      {id: 'Media', title: 'Media'},
      {id: 'Gaming', title: 'Gaming'},
      {id: 'Automotive', title: 'Automotive'},
      {id: 'Spots& E-Sports', title: 'Sports & E-Sports'},
      {id: 'Consumer Package Goods (CPG)', title: 'Consumer Packaged Goods (CPG)'},
      {id: 'Technology', title: 'Technology'},
      {id: 'Retail', title: 'Retail'},
      {id: 'Beauty', title: 'Beauty'},
      {id: 'Fashion', title: 'Fashion'},
      {id: 'Luxury goods & services', title: 'Luxury goods & services'},
      {id: 'Telecommunications', title: 'Telecommunications'},
      {id: 'Finance', title: 'Finance'},
      {id: 'Travel', title: 'Travel'},
      {id: 'Hospitality', title: 'Hospitality'},
      {id: 'Goverment', title: 'Government'},
      {id: 'Education', title: 'Education'},
      {id: 'Healthcare', title: 'Healthcare'},
      {id: 'Marketing/Advertising Agency', title: 'Marketing/Advertising Agency'},
      {id: 'Publishing', title: 'Publishing'},
      {id: 'Other', title: 'Other'},
    ],
    Countrys: [
      {id: 'Afghanistan', title: 'Afghanistan'},
      {id: 'Åland Islands', title: 'Åland Islands'},
      {id: 'Albania', title: 'Albania'},
      {id: 'Algeria', title: 'Algeria'},
      {id: 'American Samoa', title: 'American Samoa'},
      {id: 'Andorra', title: 'Andorra'},
      {id: 'Angola', title: 'Angola'},
      {id: 'Anguilla', title: 'Anguilla'},
      {id: 'Antarctica', title: 'Antarctica'},
      {id: 'Antigua and Barbuda', title: 'Antigua and Barbuda'},
      {id: 'Argentina', title: 'Argentina'},
      {id: 'Armenia', title: 'Armenia'},
      {id: 'Aruba', title: 'Aruba'},
      {id: 'Asia/Pacific Region', title: 'Asia/Pacific Region'},
      {id: 'Australia', title: 'Australia'},
      {id: 'Austria', title: 'Austria'},
      {id: 'Azerbaijan', title: 'Azerbaijan'},
      {id: 'Bahamas', title: 'Bahamas'},
      {id: 'Bahrain', title: 'Bahrain'},
      {id: 'Bangladesh', title: 'Bangladesh'},
      {id: 'Barbados', title: 'Barbados'},
      {id: 'Belarus', title: 'Belarus'},
      {id: 'Belgium', title: 'Belgium'},
      {id: 'Belize', title: 'Belize'},
      {id: 'Benin', title: 'Benin'},
      {id: 'Bermuda', title: 'Bermuda'},
      {id: 'Bhutan', title: 'Bhutan'},
      {id: 'Bolivia', title: 'Bolivia'},
      {id: 'Bosnia and Herzegovina', title: 'Bosnia and Herzegovina'},
      {id: 'Botswana', title: 'Botswana'},
      {id: 'Bouvet Island', title: 'Bouvet Island'},
      {id: 'Brazil', title: 'Brazil'},
      {id: 'British Indian Ocean Territory', title: 'British Indian Ocean Territory'},
      {id: 'British Virgin Islands', title: 'British Virgin Islands'},
      {id: 'Brunei', title: 'Brunei'},
      {id: 'Bulgaria', title: 'Bulgaria'},
      {id: 'Burkina Faso', title: 'Burkina Faso'},
      {id: 'Burundi', title: 'Burundi'},
      {id: 'Cambodia', title: 'Cambodia'},
      {id: 'Cameroon', title: 'Cameroon'},
      {id: 'Canada', title: 'Canada'},
      {id: 'Cape Verde', title: 'Cape Verde'},
      {id: 'Caribbean Netherlands', title: 'Caribbean Netherlands'},
      {id: 'Cayman Islands', title: 'Cayman Islands'},
      {id: 'Central African Republic', title: 'Central African Republic'},
      {id: 'Chad', title: 'Chad'},
      {id: 'Chile', title: 'Chile'},
      {id: 'China', title: 'China'},
      {id: 'Christmas Island', title: 'Christmas Island'},
      {id: 'Cocos (Keeling) Islands', title: 'Cocos (Keeling) Islands'},
      {id: 'Colombia', title: 'Colombia'},
      {id: 'Comoros', title: 'Comoros'},
      {id: 'Congo', title: 'Congo'},
      {id: 'Cook Islands', title: 'Cook Islands'},
      {id: 'Costa Rica', title: 'Costa Rica'},
      {id: 'Cote d\'Ivoire', title: 'Cote d\'Ivoire'},
      {id: 'Croatia', title: 'Croatia'},
      {id: 'Cuba', title: 'Cuba'},
      {id: 'Curaçao', title: 'Curaçao'},
      {id: 'Cyprus', title: 'Cyprus'},
      {id: 'Czech Republic', title: 'Czech Republic'},
      {id: 'Democratic Republic of the Congo', title: 'Democratic Republic of the Congo'},
      {id: 'Denmark', title: 'Denmark'},
      {id: 'Djibouti', title: 'Djibouti'},
      {id: 'Dominica', title: 'Dominica'},
      {id: 'Dominican Republic', title: 'Dominican Republic'},
      {id: 'East Timor', title: 'East Timor'},
      {id: 'Ecuador', title: 'Ecuador'},
      {id: 'Egypt', title: 'Egypt'},
      {id: 'El Salvador', title: 'El Salvador'},
      {id: 'Equatorial Guinea', title: 'Equatorial Guinea'},
      {id: 'Eritrea', title: 'Eritrea'},
      {id: 'Estonia', title: 'Estonia'},
      {id: 'Ethiopia', title: 'Ethiopia'},
      {id: 'Europe', title: 'Europe'},
      {id: 'Falkland Islands', title: 'Falkland Islands'},
      {id: 'Faroe Islands', title: 'Faroe Islands'},
      {id: 'Fiji', title: 'Fiji'},
      {id: 'Finland', title: 'Finland'},
      {id: 'France', title: 'France'},
      {id: 'French Guiana', title: 'French Guiana'},
      {id: 'French Polynesia', title: 'French Polynesia'},
      {id: 'French Southern and Antarctic Lands', title: 'French Southern and Antarctic Lands'},
      {id: 'Gabon', title: 'Gabon'},
      {id: 'Gambia', title: 'Gambia'},
      {id: 'Georgia', title: 'Georgia'},
      {id: 'Germany', title: 'Germany'},
      {id: 'Ghana', title: 'Ghana'},
      {id: 'Gibraltar', title: 'Gibraltar'},
      {id: 'Greece', title: 'Greece'},
      {id: 'Greenland', title: 'Greenland'},
      {id: 'Grenada', title: 'Grenada'},
      {id: 'Guadeloupe', title: 'Guadeloupe'},
      {id: 'Guam', title: 'Guam'},
      {id: 'Guatemala', title: 'Guatemala'},
      {id: 'Guernsey', title: 'Guernsey'},
      {id: 'Guinea', title: 'Guinea'},
      {id: 'Guinea-Bissau', title: 'Guinea-Bissau'},
      {id: 'Guyana', title: 'Guyana'},
      {id: 'Haiti', title: 'Haiti'},
      {id: 'Heard Island and McDonald Islands', title: 'Heard Island and McDonald Islands'},
      {id: 'Honduras', title: 'Honduras'},
      {id: 'Hong Kong', title: 'Hong Kong'},
      {id: 'Hungary', title: 'Hungary'},
      {id: 'Iceland', title: 'Iceland'},
      {id: 'India', title: 'India'},
      {id: 'Indonesia', title: 'Indonesia'},
      {id: 'Iran', title: 'Iran'},
      {id: 'Iraq', title: 'Iraq'},
      {id: 'Ireland', title: 'Ireland'},
      {id: 'Isle of Man', title: 'Isle of Man'},
      {id: 'Israel', title: 'Israel'},
      {id: 'Italy', title: 'Italy'},
      {id: 'Jamaica', title: 'Jamaica'},
      {id: 'Japan', title: 'Japan'},
      {id: 'Jersey', title: 'Jersey'},
      {id: 'Jordan', title: 'Jordan'},
      {id: 'Kazakhstan', title: 'Kazakhstan'},
      {id: 'Kenya', title: 'Kenya'},
      {id: 'Kiribati', title: 'Kiribati'},
      {id: 'Kuwait', title: 'Kuwait'},
      {id: 'Kyrgyzstan', title: 'Kyrgyzstan'},
      {id: 'Laos', title: 'Laos'},
      {id: 'Latvia', title: 'Latvia'},
      {id: 'Lebanon', title: 'Lebanon'},
      {id: 'Lesotho', title: 'Lesotho'},
      {id: 'Liberia', title: 'Liberia'},
      {id: 'Libya', title: 'Libya'},
      {id: 'Liechtenstein', title: 'Liechtenstein'},
      {id: 'Lithuania', title: 'Lithuania'},
      {id: 'Luxembourg', title: 'Luxembourg'},
      {id: 'Macau', title: 'Macau'},
      {id: 'Macedonia (FYROM)', title: 'Macedonia (FYROM)'},
      {id: 'Madagascar', title: 'Madagascar'},
      {id: 'Malawi', title: 'Malawi'},
      {id: 'Malaysia', title: 'Malaysia'},
      {id: 'Maldives', title: 'Maldives'},
      {id: 'Mali', title: 'Mali'},
      {id: 'Malta', title: 'Malta'},
      {id: 'Marshall Islands', title: 'Marshall Islands'},
      {id: 'Martinique', title: 'Martinique'},
      {id: 'Mauritania', title: 'Mauritania'},
      {id: 'Mauritius', title: 'Mauritius'},
      {id: 'Mayotte', title: 'Mayotte'},
      {id: 'Mexico', title: 'Mexico'},
      {id: 'Micronesia', title: 'Micronesia'},
      {id: 'Moldova', title: 'Moldova'},
      {id: 'Monaco', title: 'Monaco'},
      {id: 'Mongolia', title: 'Mongolia'},
      {id: 'Montenegro', title: 'Montenegro'},
      {id: 'Montserrat', title: 'Montserrat'},
      {id: 'Morocco', title: 'Morocco'},
      {id: 'Mozambique', title: 'Mozambique'},
      {id: 'Myanmar (Burma)', title: 'Myanmar (Burma)'},
      {id: 'Namibia', title: 'Namibia'},
      {id: 'Nauru', title: 'Nauru'},
      {id: 'Nepal', title: 'Nepal'},
      {id: 'Netherlands', title: 'Netherlands'},
      {id: 'Netherlands Antilles', title: 'Netherlands Antilles'},
      {id: 'New Caledonia', title: 'New Caledonia'},
      {id: 'New Zealand', title: 'New Zealand'},
      {id: 'Nicaragua', title: 'Nicaragua'},
      {id: 'Niger', title: 'Niger'},
      {id: 'Nigeria', title: 'Nigeria'},
      {id: 'Niue', title: 'Niue'},
      {id: 'Norfolk Island', title: 'Norfolk Island'},
      {id: 'North Korea', title: 'North Korea'},
      {id: 'Northern Mariana Islands', title: 'Northern Mariana Islands'},
      {id: 'Norway', title: 'Norway'},
      {id: 'Oman', title: 'Oman'},
      {id: 'Pakistan', title: 'Pakistan'},
      {id: 'Palau', title: 'Palau'},
      {id: 'Palestine', title: 'Palestine'},
      {id: 'Panama', title: 'Panama'},
      {id: 'Papua New Guinea', title: 'Papua New Guinea'},
      {id: 'Paraguay', title: 'Paraguay'},
      {id: 'Peru', title: 'Peru'},
      {id: 'Philippines', title: 'Philippines'},
      {id: 'Pitcairn Islands', title: 'Pitcairn Islands'},
      {id: 'Poland', title: 'Poland'},
      {id: 'Portugal', title: 'Portugal'},
      {id: 'Puerto Rico', title: 'Puerto Rico'},
      {id: 'Qatar', title: 'Qatar'},
      {id: 'Réunion', title: 'Réunion'},
      {id: 'Romania', title: 'Romania'},
      {id: 'Russia', title: 'Russia'},
      {id: 'Rwanda', title: 'Rwanda'},
      {id: 'Saint Barthélemy', title: 'Saint Barthélemy'},
      {id: 'Saint Helena', title: 'Saint Helena'},
      {id: 'Saint Kitts and Nevis', title: 'Saint Kitts and Nevis'},
      {id: 'Saint Lucia', title: 'Saint Lucia'},
      {id: 'Saint Martin', title: 'Saint Martin'},
      {id: 'Saint Pierre and Miquelon', title: 'Saint Pierre and Miquelon'},
      {id: 'Saint Vincent and the Grenadines', title: 'Saint Vincent and the Grenadines'},
      {id: 'Samoa', title: 'Samoa'},
      {id: 'San Marino', title: 'San Marino'},
      {id: 'Sao Tome and Principe', title: 'Sao Tome and Principe'},
      {id: 'Saudi Arabia', title: 'Saudi Arabia'},
      {id: 'Senegal', title: 'Senegal'},
      {id: 'Serbia', title: 'Serbia'},
      {id: 'Seychelles', title: 'Seychelles'},
      {id: 'Sierra Leone', title: 'Sierra Leone'},
      {id: 'Singapore', title: 'Singapore'},
      {id: 'Sint Maarten', title: 'Sint Maarten'},
      {id: 'Slovakia', title: 'Slovakia'},
      {id: 'Slovenia', title: 'Slovenia'},
      {id: 'Solomon Islands', title: 'Solomon Islands'},
      {id: 'Somalia', title: 'Somalia'},
      {id: 'South Africa', title: 'South Africa'},
      {id: 'South Georgia and the South Sandwich Islands', title: 'South Georgia and the South Sandwich Islands'},
      {id: 'South Korea', title: 'South Korea'},
      {id: 'South Sudan', title: 'South Sudan'},
      {id: 'Spain', title: 'Spain'},
      {id: 'Sri Lanka', title: 'Sri Lanka'},
      {id: 'Sudan', title: 'Sudan'},
      {id: 'Suriname', title: 'Suriname'},
      {id: 'Svalbard and Jan Mayen', title: 'Svalbard and Jan Mayen'},
      {id: 'Swaziland', title: 'Swaziland'},
      {id: 'Sweden', title: 'Sweden'},
      {id: 'Switzerland', title: 'Switzerland'},
      {id: 'Syria', title: 'Syria'},
      {id: 'Taiwan', title: 'Taiwan'},
      {id: 'Tajikistan', title: 'Tajikistan'},
      {id: 'Tanzania', title: 'Tanzania'},
      {id: 'Thailand', title: 'Thailand'},
      {id: 'Togo', title: 'Togo'},
      {id: 'Tokelau', title: 'Tokelau'},
      {id: 'Tonga', title: 'Tonga'},
      {id: 'Trinidad and Tobago', title: 'Trinidad and Tobago'},
      {id: 'Tunisia', title: 'Tunisia'},
      {id: 'Turkey', title: 'Turkey'},
      {id: 'Turkmenistan', title: 'Turkmenistan'},
      {id: 'Turks and Caicos Islands', title: 'Turks and Caicos Islands'},
      {id: 'Tuvalu', title: 'Tuvalu'},
      {id: 'U.S. Virgin Islands', title: 'U.S. Virgin Islands'},
      {id: 'Uganda', title: 'Uganda'},
      {id: 'Ukraine', title: 'Ukraine'},
      {id: 'United Arab Emirates', title: 'United Arab Emirates'},
      {id: 'United Kingdom', title: 'United Kingdom'},
      {id: 'United States', title: 'United States'},
      {id: 'United States Minor Outlying Islands', title: 'United States Minor Outlying Islands'},
      {id: 'Uruguay', title: 'Uruguay'},
      {id: 'Uzbekistan', title: 'Uzbekistan'},
      {id: 'Vanuatu', title: 'Vanuatu'},
      {id: 'Vatican City', title: 'Vatican City'},
      {id: 'Venezuela', title: 'Venezuela'},
      {id: 'Vietnam', title: 'Vietnam'},
      {id: 'Wallis and Futuna', title: 'Wallis and Futuna'},
      {id: 'Western Sahara', title: 'Western Sahara'},
      {id: 'Yemen', title: 'Yemen'},
      {id: 'Zambia', title: 'Zambia'},
      {id: 'Zimbabwe', title: 'Zimbabwe'},
    ],
  }),
  methods: {
    async Submit() {
      let vm = this;
      if (this.$refs.form.validate()) {
        vm.isLoadingBtn = true;
        const values = {
          CompanyEmail: this.email,
          FName: this.fname,
          LName: this.lname,
          Industry: this.industry,
          CompanySite: this.CompanySite,
          Country: this.Country,
        };
        //todo load start parametrs
        vm.$store
          .dispatch("RegistrationBusinessForm1", { vm, values })
          .then((responce) => {
            debugger
            console.log(responce);
            if(responce.status.STAT == "ALSOK"){
              this.dialogTitle = 'You have successfully subscribed to TikTok for Business Newsletter.'
              this.$refs.form.reset()
            }else{
              this.dialogTitle = 'You have already subscribed to TikTok for Business Newsletter.'
            }
            this.dialogText = responce.status.MSG;
            this.dialog = true;
            vm.isLoadingBtn = false;
          })
          .catch((status) => {
            console.log("CustomerDocumentAdd", status);
            this.dialogTitle = 'You have already subscribed to TikTok for Business Newsletter.'
            this.dialog = true;
            //vm.isLoading = false;
            vm.isLoadingBtn = false;
            //vm.$ParsError({ status, vm });
          });
      }
    },
    GetNextFileInformation: function (documentHash) {
      let vm = this;
      vm.isLoading = true;
      vm.isLoadingBtn = true;
      const values = {
        prizeHash: this.$route.params.hash,
        documentHash: documentHash,
      };
      console.log("CustomerDocumentList", this.fileInfo.DocumentHash);
      //todo load start parametrs
      vm.$store
        .dispatch("CustomerDocumentList", { vm, values })
        .then((responce) => {
          console.log(responce);
          if (responce.status.STAT == "ALSOK") {
            this.fileInfo.DocumentText = responce.documentText;
            this.inputs = [];
            for (var i = 0; i < responce.inputs.length; i++) {
              this.inputs.push(responce.inputs[i]);
            }
            if (responce.regions && responce.regions.length > 0) {
              this.regions = responce.regions;
            }
          } else {
            this.dialogText = responce.status.MSG;
            console.log("CustomerDocumentList", responce.status);
            this.dialog = true;
            //vm.$ParsError({ status: responce.status, vm });
          }
          vm.isLoadingBtn = false;
          vm.isLoading = false;
        })
        .catch((status) => {
          this.dialogText = status.MSG;
          console.log("CustomerDocumentList", status);
          this.dialog = true;
          vm.isLoading = false;
          vm.isLoadingBtn = false;
          //vm.$ParsError({ status, vm });
        });
    },
  },
  mounted: function () {
    console.log("mounted");
    let vm = this;

    if (this.$route.params.hash) {
      const values = {
        prizeHash: this.$route.params.hash,
      };
      //todo load start parametrs
      vm.$store
        .dispatch("CustomerDocumentList", { vm, values })
        .then((responce) => {
          console.log(responce);
          if (responce.status.STAT == "ALSOK") {
            if (responce.list) {
              this.list = responce.list;
              this.fileInfo = this.list[0];
              this.inputs = responce.inputs;
              if (responce.regions && responce.regions.length > 0) {
                this.regions = responce.regions;
              }
            }
            /*
              vm.$store.commit("setCustomerTransactionDate", {
                transactionDate: responce.transaction,
              });
              */
          } else {
            this.dialogText = responce.status.MSG;
            console.log("CustomerDocumentList", responce.status);
            this.dialog = true;
          }
          vm.isLoading = false;
        })
        .catch((status) => {
          this.dialogText = status.MSG;
          console.log("CustomerDocumentList", status);
          this.dialog = true;
          vm.isLoading = false;
          //vm.$ParsError({ status, vm });
        });

      setTimeout(this.successLoading, 500);
    } else {
      vm.isLoading = false;
    }
  },
  computed: {
    typeCooperations: function() {
      return [
      {id: 'Партнер', title: this.$t('Партнер')},
      {id: 'Заказчик', title: this.$t('Заказчик')},
    ]},
    isHash: function () {
      return this.$route.params.hash;
    },
    reqRules() {
      let temp = this.land
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.land = temp
      return [
        v => (!!v && !!v.trim()) || this.$t("required")
      ];
    },
    reqBoolRules() {
      return [
        v => !!v || this.$t("required")
      ];
    },
    emailRules() {
      let temp = this.land
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.land = temp
      return [
        v => !!v || this.$t("required"),
        v => {
          if(v && v.length != v.replaceAll('@', '').length + 1) return this.$t("Bad email");
          return /.+@.+\..+/.test(v) || this.$t("Bad email");
        }
      ];
    }
  },
};
</script>
<style>
  .bgimg {
    background-repeat: repeat-y;
    background-image: url('~@/assets/eu-signup-form-pc.675e5f2.png');
  }
  .h2-title {
    font-size: 64px;
    font-weight: 700;
    font-family: "SofiaProBold","SofiaPro","PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",HelveticaNeue,Helvetica,Arial,sans-serif;
    line-height: 1;
  }
  .tb-title {
        font-size: 20px;
    line-height: 1.4;
  }
  .btn-submit {
    padding: 15px 20px;
    border: 2px solid #fe2c55;
    background: #fe2c55;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
  }
  .v-btn__content {
    text-transform: none !important;
    font-size: 20px;
    font-weight: 600;
  }
  .v-btn:not(.v-btn--round).v-size--x-large{
    height: 58px;
  }
  .v-text-field--outlined fieldset{
    border-width: 1px;
    border-color: white;
  }
  .tt-link {
    text-decoration: none;
        color: #3f71c3 !important;
  }
  .v-card__title {
        word-break: break-word !important;
  }
</style>