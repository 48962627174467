import Vue from 'vue'
import VueRouter from 'vue-router'
/*
import Form1 from '../views/Form1.vue'
import Form2 from '../views/Form2.vue'
*/
import TikTok1 from '../views/TikTok1.vue'

Vue.use(VueRouter)

const routes = [
  /*
  {
    path: '/online',
    name: 'Form1',
    component: Form1,
    meta: {
      dialogCloseRegistration: true,
    }
  },
  {
    path: '/EurasiaDataCenter/offline',
    name: 'Form2',
    component: Form2,
    meta: {
      dialogCloseRegistration: true,
    }
  },
  */
  {
    path: '/',
    name: 'TikTok1',
    component: TikTok1,
    meta: {
      dialogCloseRegistration: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
